<template>
	<div>
		<a-spin :spinning="loading">
			<Header title="角色关联用户列表" @back="onBack"></Header>
			<a-table :dataSource="list" :columns="columns" :pagination="pagination">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'time'">
						{{ transDateTime(record.lastTime) }}
					</template>
					<template v-else-if="column.key === 'action'">
						<a v-permission="['system_manage_role_see_edit']" @click="onUnBind(record)">解绑</a>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getRoleUserList, unbindByUserId } from '@/service/modules/system';
	export default {
		components: {Icon, Header},
		props: {
			id: {
				type: null,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				list: [],
				columns: [{
					title: '用户称谓',
					dataIndex: 'nickname'
				}, {
					title: '用户名',
					dataIndex: 'username'
				}, {
					title: '最后登录时间',
					key: 'time'
				}, {
					title: '最后登录IP',
					dataIndex: 'ip'
				}, {
					title: '操作',
					key: 'action'
				}],
				pagination: {
				  showSizeChanger: true,
				  showQuickJumper: true,
				  size: "middle",
				  showTotal: (total) => {
				    return "共 " + total + " 条";
				  },
				  total: 0,
				  current: 1,
				  pageSize: 10,
				  onChange: (page) => {
				    this.pagination.current = page;
				    this.getData();
				  },
				  onShowSizeChange: (page, pageSize) => {
				    this.pagination.current = 1;
				    this.pagination.pageSize = pageSize;
				    this.getData();
				  }
				},
			}
		},
		created() {
			if(this.id) {
				this.getData();
			}
		},
		methods: {
			onBack() {
				this.$emit('back', false)
			},
			async getData() {
				this.loading = true;
				let ret = await getRoleUserList({
					id: this.id,
					page: this.pagination.current,
					pageSize: this.pagination.pageSize
				});
				this.loading = false;
				if (ret.code === 200) {
					this.pagination.total = ret.data.totalCount;
					this.list = ret.data.list;
				}
			},
			onUnBind(item) {
				this.$confirm({
					title: '提示',
					content: '确定解除绑定吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await unbindByUserId({
							roleId: this.id,
							userId: item.id
						});
						this.loading = true;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
						}
					}
				})
			}
		}
	}
</script>

<style>
</style>
